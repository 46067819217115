import '../../css/home/Blog.css'
type Props = {}

function Blog({}: Props) {
  return (
    <div className="blog">
        <div className="blogTitle">
            About Eyebrow Threading, what is eyebrow threading
        </div>
        <p>
        eyebrow threading
        </p>
        <p>
        eyebrow threading near me
        </p>
        <p>
        eyebrow threading round rock
        </p>
        <p>
        eyebrow threading austin
        </p>
        <p>
        eyebrow threading pflugerville, eyebrow threading heb, eyebrow threading video, eyebrow threading cost, eyebrow threading north austin
        </p>
        <p>
            eyebrow waxing,
            Eyebrow threading reddit
Eyebrow threading cost
Eyebrow threading near me
Eyebrow threading Round Rock
Eyebrow threading Pflugerville
Eyebrow threading Austin
Eyebrow threading north Austin
Eyebrow Threading HEB
Lash lounge
Lashes
Nail salon
        </p>
    </div>
  )
}

export default Blog